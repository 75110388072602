/**
 *
 * global.js
 *
 * Vue plugin with global functions
 *
 * @author Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.
 *
 */

const globalPlugin = {
    install(Vue) {
        /**
         * Shows API errors
         */
        Vue.prototype.processError = function(error) {
            // Check network error
            if (!error.response) this.$store.commit('setErrors', error);
            else if (!error.response || !error.response.status || error.message === 'Network Error') {
                this.$store.commit('closeLoading');
                this.$store.commit('setOutOfService', true);
            } else this.$store.commit('setErrors', error);
        };

        /**
         * Get item field value
         */
        Vue.prototype.getItemFieldValue = function(item, fieldname, language, multiple) {
            // Get field
            let field = this.$store.state.fields.find(f => f.itemType === item.type && f.name === fieldname);
            let value = null;

            // Check if is a main field or a secondary field
            if (item[fieldname] !== undefined) {
                // Check if we want all values or only one
                if (multiple) {
                    if (Array.isArray(item[fieldname])) value = item[fieldname];
                    else value = [item[fieldname]];
                    if (field.translate) value = value.map(v => this.$gettext(v));
                } else {
                    if (Array.isArray(item[fieldname])) value = item[fieldname][0];
                    else value = item[fieldname];
                    if (field.translate) value = this.$gettext(value);
                }
            } else if (item.secondaryFields[item.type + '_' + language + '_' + fieldname]) {
                // Check if we want all values or only one
                if (multiple) {
                    if (Array.isArray(item.secondaryFields[item.type + '_' + language + '_' + fieldname])) value = item.secondaryFields[item.type + '_' + language + '_' + fieldname];
                    else value = [item.secondaryFields[item.type + '_' + language + '_' + fieldname]];
                    if (field.translate) value = value.map(v => this.$gettext(v));
                } else {
                    if (Array.isArray(item.secondaryFields[item.type + '_' + language + '_' + fieldname])) value = item.secondaryFields[item.type + '_' + language + '_' + fieldname][0];
                    else value = item.secondaryFields[item.type + '_' + language + '_' + fieldname];
                    if (field.translate) value = this.$gettext(value);
                }
            }

            return value;
        };

        /**
         * Print item description in a list
         */
        Vue.prototype.printItemDescription = function(item, section) {
            // Check if exists a template for this item subtype in this section, or not
            let template = undefined;

            // Check template specific for section
            if (section !== null) template = this.$store.state.itemTemplates.find(it => it.sections.includes(section.id))?.template;

            // If not exists, get default template por item type and subtype
            if (template === undefined && this.$store.state.defaultItemTemplates[item.type][item.subtype]) template = this.$store.state.defaultItemTemplates[item.type][item.subtype].template;

            // Print template, if exists
            if (template !== undefined) {
                // Get all () expressions inside template
                let matches = [...template.matchAll(/\(([^)]+)\)/g)];

                matches.forEach(m => {
                    // Search fields in set
                    let matches2 = [...m[1].matchAll(/\[([a-zA-Z_\-#]+)\]/g)];
                    matches2.forEach(sm => {
                        // Get field and value
                        let value = this.getFieldValues(item, sm[1]);
                        let field = this.$store.state.fields.find(f => f.itemType == item.type && f.name == sm[1]);

                        if (field !== undefined) {
                            // Translate values if necessary
                            if (field.translate) {
                                if (Array.isArray(value)) {
                                    value.forEach(v => this.$gettext(v))
                                    value = value.join('; ');
                                } else value = this.$gettext(value);
                            } else if (Array.isArray(value)) value = value.join(field.separator);

                            // Replace expression for value
                            if (value !== '') template = template.replaceAll('(' + m[1] + ')', m[1].replaceAll('[' + sm[1] + ']', value));
                            else template = template.replaceAll('(' + m[1] + ')', '').trim();
                        }
                    });
                });

                // Replace all single fields
                matches = [...template.matchAll(/\[([a-zA-Z_\-#]+)\]/g)];
                matches.forEach(m => {
                    // Get field value
                    let value = this.getFieldValues(item, m[1]);
                    let field = this.$store.state.fields.find(f => f.itemType == item.type && f.name == m[1]);

                    if (field !== undefined) {
                        // Translate values if necessaty
                        if (field.translate) {
                            if (Array.isArray(value)) {
                                value.forEach(v => this.$gettext(v));
                                value = value.join('; ');
                            } else value = this.$gettext(value);
                        } else if (Array.isArray(value)) value = value.join(field.separator);

                        // Replace expression for value
                        if (value !== '') template = template.replaceAll(m[0], value);
                        else template = template.replaceAll(m[0], '').trim();
                    }
                });

                // Replace translations
                matches = [...template.matchAll(/{([a-zA-Z\s\\.]+)}/g)];
                matches.forEach(m => {
                    // Replace match for translation
                    template = template.replaceAll(m[0], this.$gettext(m[1]));
                });

                // Replace citation data
                template = template.replace('##citationData##', this.citationData(item));

                // Replace num news
                template = template.replace('##numNews##', this.numNews(item));

                return template.replace(/##[a-zA-z0-9\s]+/ig, '').replaceAll('##', '');
            } else return this.$gettext(item.type) + ' - ' + this.$gettext(item.subtype);
        };

        /**
         * Get field values in current language
         */
        Vue.prototype.getFieldValues = function(item, fieldname) {
            let value = this.getItemFieldValue(item, fieldname, this.$language.current, true);
            return (value === null) ? '' : value;
        };

        /**
         * Get item main URL
         */
        Vue.prototype.getItemURL = function(item) {
            // Get all urls
            let urls = this.getItemFieldValue(item, 'accessURL', this.$language.current, true);
            if (urls !== null) {
                if (Array.isArray(urls)) {
                    urls = urls.map(u => u.split('##'));
                    urls.sort(function(u1, u2) {
                        if (u1[1] > u2[1]) return 1;
                        else return -1;
                    });
                    return urls[0][0].replace(/##[a-zA-z0-9\s]+/ig, '');

                } else return urls.replace(/##[a-zA-z0-9\s]+/ig, '');
            }
        };

        /**
         * Gets field name
         */
        Vue.prototype.getFieldname = function(fieldname, itemType) {
            if (this.specialFields.includes(fieldname)) return fieldname;
            else return itemType + '_' + this.$language.current + '_' + fieldname;
        };

        /**
         * Inits header and search
         */
        Vue.prototype.initsHeaderSearchLanguage = function(image, language, next) {
            this.$store.commit('initsSearch', this.$gettext('All'));
            this.$store.commit('setHeaderImage', image);
            // Set initial language based on route
            if (language != '' && this.$store.state.firstRoute) this.$language.current = language;
            this.$store.commit('setFirstRoute', false);
            next();
        };

        /**
         * Builds the route changing parameter values
         */
        Vue.prototype.buildRoute = function(newParametersValues) {
            // Get route parameters
            let paramValues = [];
            // Sections and search
            if (this.$route.params.paramValues !== undefined) paramValues = this.$route.params.paramValues.split('/');
            // Item profile
            else if (this.$route.params.itemId !== undefined) {
                paramValues = this.$route.params.itemId.split('/');
                // Delete first position (item URL)
                paramValues.shift();
            }

            // Add, replace or delete new parameters and values
            newParametersValues.forEach(pv => {
                // Search index of the selected parameter
                let index = paramValues.findIndex(v => v === pv.parameterName);
                // Parameter not found, add parameter and value
                if (index === -1) {
                    if (pv.value !== '') {
                        paramValues.push(pv.parameterName)
                        paramValues.push(pv.value);
                    }
                } else {
                    // If value is empty, delete parameter, else replace
                    if (pv.value === '') paramValues.splice(index, 2);
                    else paramValues[index + 1] = pv.value;
                }
            });

            // Prepare route parameters
            if (paramValues.length > 0) paramValues = '/' + paramValues.join('/');
            else paramValues = '';

            // Push new route with params
            if (this.section !== null && this.section !== undefined) return '/' + this.section.path[this.$language.current] + paramValues;
            else if (this.item !== null && this.item !== undefined) return '/' + this.getItemURL(this.item).replace(this.$store.state.config.URL + '/', '') + paramValues;
            else if (this.simulation !== null && this.simulation !== undefined) return '/' + this.$gettext('simulations') + '/' + this.simulation.url + paramValues;
            else return this.$gettext('/search') + paramValues;
        };

        /**
         * Transforms time in seconds to minutes
         */
        Vue.prototype.transformTime = function(value) {
            let minutes = Math.floor(value / 60);
            if (minutes.toString().length < 2) minutes = '0' + minutes;
            let seconds = Math.floor(value % 60);
            if (seconds.toString().length < 2) seconds = '0' + seconds;

            return minutes + ':' + seconds + ' m';
        };

        /**
         * Filter query
         */
        Vue.prototype.filterQuery = function(value) {
            let query = value.replaceAll(' ', '\\ ');
            query = query.replaceAll('\'', '\\\'');
            query = query.replaceAll('+', '\\+');
            query = query.replaceAll('-', '\\-');
            query = query.replaceAll('&&', '\\&&');
            query = query.replaceAll('||', '\\||');
            query = query.replaceAll('!', '\\!');
            query = query.replaceAll('(', '\\(');
            query = query.replaceAll(')', '\\)');
            query = query.replaceAll('{', '\\{');
            query = query.replaceAll('}', '\\}');
            query = query.replaceAll('[', '\\[');
            query = query.replaceAll(']', '\\]');
            query = query.replaceAll('^', '\\^');
            query = query.replaceAll('~', '\\~');
            query = query.replaceAll('?', '\\?');
            query = query.replaceAll(':', '\\:');
            query = query.replaceAll('/', '\\/');
            query = query.replaceAll('"', '\\"');
            query = query.replaceAll(' AND ', ' "AND" ');
            query = query.replaceAll(' OR ', ' "OR" ');

            return query;
        };

        /**
         * Cut text by words
         */
        Vue.prototype.cutWords = function(text) {
            let words = text.split(' ');
            if (words.length > 100) return words.splice(0, 100).join(' ') + '...';
            else return text;
        };

        /**
         * Prints citation data in items list
         */
        Vue.prototype.citationData = function(item) {
            // Get citation fields from item
            let volume = this.getItemFieldValue(item, 'volume', this.$language.current, false);
            let number = this.getItemFieldValue(item, 'number', this.$language.current, false);
            let startPage = this.getItemFieldValue(item, 'startPage', this.$language.current, false);
            let endPage = this.getItemFieldValue(item, 'endPage', this.$language.current, false);

            // Build citation data
            let citationData = [];
            if (volume !== null) citationData.push(this.$gettext('Vol. ' + volume));
            if (number !== null) citationData.push(this.$gettext('num. ' + number));
            if (startPage !== null) {
                let pages = 'p. ' + startPage;
                if (startPage != endPage) pages += '-' + endPage;
                citationData.push(pages);
            } else if (endPage !== null) citationData.push(endPage);

            // Join fields
            citationData = citationData.join(' ').trim();
            if (citationData != '') citationData += '<br />';

            return citationData;
        };

        /**
         * Prints number of news
         */
        Vue.prototype.numNews = function(item) {
            let news = this.getItemFieldValue(item, 'news', this.$language.current, true);
            if (news !== null && news.length > 0) return "<strong>" + this.$gettext("Press room") + ":</strong> " + news.length + "<br /><br />";
            else return "";
        };

        /**
         * Converts utf-8 string to base 64 string
         */
        Vue.prototype.UTF8ToB64 = function(str) {
            return window.btoa(unescape(encodeURIComponent(str)));
        };

        /**
         * Converts base 64 string string to utf-8 string
         */
        Vue.prototype.b64ToUTF8 = function b64ToUTF8(str) {
            return decodeURIComponent(escape(atob(str)));
        };

        /**
         * Cleans form data of null values
         */
        Vue.prototype.cleanFormData = function (formData) {
            let formDataCleaned = {};

            Object.keys(formData).forEach(key => {
                if (formData[key] != null) {
                    if (typeof formData[key] === 'string') {
                        if (formData[key] != '') formDataCleaned[key] = formData[key];
                    } else if (Array.isArray(formData[key])) formDataCleaned[key] = formData[key];
                    else if (typeof formData[key] === 'object') {
                        if (Object.keys(formData[key]).length > 0) {
                            let keyCleaned = this.cleanFormData(formData[key]);
                            if (Object.keys(keyCleaned).length > 0) formDataCleaned[key] = keyCleaned;
                        }
                    } else formDataCleaned[key] = formData[key];
                }
            });

            return formDataCleaned;
        };

        /**
         * Show a message
         */
        Vue.prototype.showMessage = function (title, message, variant, append) {
            this.$bvToast.toast(message, {
                title: title,
                toaster: 'b-toaster-top-center',
                solid: true,
                appendToast: append,
                variant: variant
            });
        };

        /**
         * Redirect to export data URLs
         */
        Vue.prototype.exportData = function (url, format) {
            // Get current url parts
            let urlParts = url.split('/');
            // Prepare URL adding format path and omitting page parameter
            let newUrl = this.$store.state.config.URL + '/';
            for (let i = 3; i < urlParts.length; i++) {
                if (i !== 3) {
                    if (urlParts[i] !== 'p' && urlParts[i- 1] !== 'p') newUrl += urlParts[i] + '/';
                } else newUrl += format + '/' + urlParts[i] + '/';
            }
            window.open(newUrl + '?locale=' + this.$language.current);
        };

        /**
         * Formats URL
         */
        Vue.prototype.formatURL = function(url) {
            if (!url.startsWith('http')) return 'http://' + url;
            else return url;
        }

        /**
         * Creates a friendly url from string
         */
        Vue.prototype.friendlyURL = function(originalString) {
            const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
            const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
            const p = new RegExp(a.split('').join('|'), 'g')

            return originalString.toString().toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                .replace(/&/g, '-and-') // Replace & with 'and'
                .replace(/[^\w\\-]+/g, '') // Remove all non-word characters
                .replace(/\\-\\-+/g, '-') // Replace multiple - with single -
                .replace(/^-+/, '') // Trim - from start of text
                .replace(/-+$/, '') // Trim - from end of text
        }

        /**
         * Set metadata
         */
        Vue.prototype.setMetadata = function(title, description, keywords) {
            document.title = title;
            document.querySelector('meta[name="title"]').setAttribute("content", title);
            document.querySelector('meta[name="description"]').setAttribute("content", description);
            document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
        }

        /**
         * Decodes an HTML
         */
        Vue.prototype.decodeHTML = function(code) {
            var div = document.createElement('div');
            div.innerHTML = code;
            return div.textContent || div.innerText || '';
        }
    }
};

export default globalPlugin;