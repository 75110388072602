/**
 *
 * index.js
 *
 * Vuex initialization
 *
 * @author Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.
 *
 */

import Vue from 'vue';
import Vuex from 'vuex';

// Add vuew
Vue.use(Vuex);

// Inits vuex object
export default new Vuex.Store({
    state: {
        user: {
            name: '',
            surname: '',
            username: '',
            email: '',
            language: '',
            role: '',
            canImpersonate: true
        },
        realUser: null,
        fields: [],
        outOfService: false,
        config: {},
        showLoading: true,
        loadingText: '',
        defaultItemTemplates: { activity: {}, person: {}, organization: {}, journal: {} },
        itemTemplates: [],
        templatesLoaded: false,
        errors: [],
        sections: [],
        headerImage: '',
        search: {
            text: '',
            searchSection: null,
            selectionText: ''
        },
        breadcrumbs: [],
        frameClass: 'frame',
        firstRoute: true,
        currentItem: null,
        currentSection: null,
        currentPath: null,
        metadata: {
            title: 'FUTUR',
            //title: 'FUTURDEV',
            subtitle: 'Website for the scientific production of UPC researchers',
            description: 'Website for the scientific production of researchers at the Universitat Politècnica de Catalunya.',
            keywords: 'futur,upc,scientific production,university',
            author: 'UPC. Servei de Biblioteques, Publicacions i Arxius.'
        }
    },
    mutations: {
        /**
         * Shows global loading
         */
        showLoading(state, text) {
            state.loadingText = text;
            state.showLoading = true;
        },

        /**
         * Close global loading
         */
        closeLoading(state) {
            state.showLoading = false;
        },

        /**
         * Set out of service flag
         */
        setOutOfService(state, value) {
            state.outOfService = value;
        },

        /**
         * Set user data
         */
        setUserData(state, user) {
            state.user = user;
        },

        /**
         * Set real user data for impersonation
         */
        setRealUserData(state, user) {
            state.realUser = user;
        },

        /**
         * Restore real user data
         */
        restoreRealUserData(state) {
            state.user = [...state.realUser];
            state.realUser = null;
        },

        /**
         * Set config
         */
        setConfig(state, config) {
            state.config = [];
            config.forEach(c => state.config[c.variable] = c.value);
        },

        /**
         * Set item templates
         */
        setItemTemplates(state, itemTemplates) {
            let itemTemplatesData = { activity: {}, person: {}, organization: {}, journal: {} };
            itemTemplates.filter(it => it.defaultType).forEach(it => {
                itemTemplatesData[it.itemType][it.itemSubtype] = it;
            });
            state.defaultItemTemplates = itemTemplatesData;
            state.itemTemplates = itemTemplates;
        },

        /**
         * Set errors
         */
        setErrors(state, error) {
            // Handle validation errors
            state.errors = [];
            if (error.response) {
                if (error.response.status == 400) {
                    error.response.data.fieldErrors.forEach(fieldError => {
                        state.errors.push(fieldError.field + ': ' + fieldError.message);
                    });
                } else state.errors.push(JSON.stringify(error.response));
            } else state.errors.push(JSON.stringify(error));
            window.scroll(0, 0);
        },

        /**
         * Clean errors
         */
        cleanErrors(state) {
            // Clean errors
            state.errors = [];
        },

        /**
         * Set sections
         */
        setSections(state, sections) {
            state.sections = sections;
        },

        /**
         * Set header image
         */
        setHeaderImage(state, image) {
            if (image !== null && image !== '') state.headerImage = 'url(' + state.config.URL + '/images/' + image + ')';
            else state.headerImage = '';
        },

        /**
         * Set fields
         */
        setFields(state, fields) {
            state.fields = fields;
        },

        /**
         * Changes section to search
         */
        changeSearchSection(state, searchData) {
            // Set values
            state.search.searchSection = searchData.section;
            state.search.selectionText = searchData.text;
        },

        /**
         * Inits search
         */
        initsSearch(state, selectionText) {
            // Set default values
            state.search.searchSection = null;
            state.search.selectionText = selectionText;
            state.search.text = '';
        },

        /**
         * Sets search text
         */
        setSearchText(state, text) {
            state.search.text = text;
        },

        /**
         * Update breadcrumbs
         */
        setBreadcrumbs(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs;
        },

        /**
         * Set frame visualization
         */
        setFrame(state, frame) {
            if (frame * 1 === 1) state.frameClass = 'frame';
            else state.frameClass = '';
        },

        /**
         * Set first route flag
         */
        setFirstRoute(state, value) {
            state.firstRoute = value;
        },

        /**
         * Mark item templates as loaded
         */
        setTemplatesLoaded(state) {
            state.templatesLoaded = true;
        },

        /**
         * Sets current item
         */
        setCurrentItem(state, item) {
            state.currentItem = item;
            state.currentSection = null;
            state.currentPath = null;
        },

        /**
         * Sets current section
         */
        setCurrentSection(state, section) {
            state.currentSection = section;
            state.currentItem = null;
            state.currentPath = null;
        },

        /**
         * Sets current path
         */
        setCurrentPath(state, path) {
            state.currentPath = path;
            state.currentSection = null;
            state.currentItem = null;
        }
    }
});